function getFirebaseImageUri(relLocatioin: string): string {
  const STORAGE_BUCKET = 'visaapp-e90c7.appspot.com'
  const bucket = STORAGE_BUCKET;
  const encodedLocation = encodeURIComponent(relLocatioin);
  const storageUrl = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodedLocation}?alt=media`;

  return storageUrl;
}

export default getFirebaseImageUri;
