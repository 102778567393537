import React, { useState } from 'react';
import {  Typography, Row, Form, Input, DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Row as UIRow, Col as UICol } from 'ui/basic';
import PopupStatus, { EPopupModes } from 'ui/Popup/PopupStatus';
import { InputFile } from 'ui/Form';
import applyVisa, { VisaApplication, ApplyVisaResponse } from 'app/services/applyVisa';
import { CountryVisa } from '../interface/types';
import Button from 'ui/Button';

const { Text } = Typography;

const steps = ['date', 'photo', 'passport', 'detail', 'checkout'];

interface FormComponentProps {
  onBack: () => void;
  numberOfTravelers: number;
  visa: CountryVisa;
}

const FormComponent: React.FC<FormComponentProps> = ({ onBack, numberOfTravelers, visa }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<VisaApplication>({
    selectedDate: null,
    visa,
    travelers: Array(numberOfTravelers).fill({
      photo: null,
      passportNumber: '',
      passportFile: null,
      firstName: '',
      lastName: '',
      email: '',
    }),
  });

  const [popupMode, setPopupMode] = useState<EPopupModes>(EPopupModes.None);
  const [popupMessage, setPopupMessage] = useState<string | undefined>(undefined);

  const disablePastDates = (current: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.valueOf() < today.getTime();
  };

  const handleNext = () => {
    if (currentStep === 0 && !formData.selectedDate) {
      return; 
    }
    if (currentStep === 1) {
      const allPhotosUploaded = formData.travelers.every((traveler) => traveler.photo !== null);
      if (!allPhotosUploaded) {
        return;
      }
    }

    if (currentStep === 2) {
      const allPassportInfoFilled = formData.travelers.every(
        (traveler) => traveler.passportNumber !== '' && traveler.passportFile !== null
      );
      if (!allPassportInfoFilled) {
        return;
      }
    }

    if (currentStep === 3) {
      const allDetailsFilled = formData.travelers.every(
        (traveler) => traveler.firstName !== '' && traveler.email !== ''
      );
      if (!allDetailsFilled) {
        return;
      }
    }

    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, selectedDate: date });
  };

  const handlePhotoDelete = (index: number) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      photo: null,
    };
    setFormData({ ...formData, travelers: updatedTravelers }); 
  };

  const handlePassportChange = (e: any, index: number) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      passportNumber: e.target.value,
    };
    setFormData({ ...formData, travelers: updatedTravelers });
  };

  const handlePassportUpload = (files: FileList, index: number) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      passportFile: files[0], 
    };
    setFormData({ ...formData, travelers: updatedTravelers });
  };

   const handlePassportDelete = (index: number) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      passportFile: null,
    };
    setFormData({ ...formData, travelers: updatedTravelers }); 
  };

  const handleInputChange = (e: any, index: number, field: string) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      [field]: e.target.value,
    };
    setFormData({ ...formData, travelers: updatedTravelers });
  };

  const handleSubmit = async () => {
    try {
      console.log('Form Data:', formData);
      const response: ApplyVisaResponse = await applyVisa(formData);

      if (response.success) {
        setPopupMode(EPopupModes.Success);
        setPopupMessage(response.message);
      } else {
        setPopupMode(EPopupModes.Error);
        setPopupMessage(response.message || 'Failed to submit the form.');
      }
    } catch (error: any) {
      setPopupMode(EPopupModes.Error);
      setPopupMessage(error.message || 'Failed to submit the form.');
    }
  };

  const closePopup = () => {
    setPopupMode(EPopupModes.None);
    setPopupMessage(undefined);
  };

  const handlePhotoChange = (files: FileList, index: number) => {
    const updatedTravelers = [...formData.travelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      photo: files[0], 
    };
    setFormData({ ...formData, travelers: updatedTravelers });
  };

  return (
    <UIRow w='100%' h='100%' style={{ background: 'white' }}>
      <div style={{ padding: '104px 24px 148px', width: '100%', height: '100%', textAlign: 'center', backgroundColor: '#f6f9fc',overflow:'scroll', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
        {/* Header */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '16px', borderBottom: '2px solid #e0e0e0' }}>
        <FontAwesomeIcon onClick={onBack} style={{ color: '#333' }} icon={faArrowLeft} />
          <Text strong style={{ fontSize: '16px', color: '#333' }}>
            Visa on {new Date().toLocaleDateString()}
          </Text>
          <div />
        </div>

        {/* Steps Header */}
        <Row justify="space-between" align="middle" style={{ marginTop: '24px' }}>
          {steps.map((step, index) => (
            <UICol key={index} w='initial'>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px' }}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="2x"
                  color={index <= currentStep ? '#40a9ff' : '#d9d9d9'}
                  style={{ marginBottom: '8px', transition: 'color 0.3s ease' }}
                />
                <Text style={{ fontSize: '12px', color: index <= currentStep ? '#333' : '#aaa' }}>
                  {step.charAt(0).toUpperCase() + step.slice(1)}
                </Text>
              </div>
            </UICol>
          ))}
        </Row>

        {/* Content */}
        <div style={{ marginTop: '40px' }}>
          {currentStep === 0 && (
            <Form layout="vertical">
              <Form.Item label="Select Date">
                <DatePicker style={{ width: '100%' }} disabledDate={disablePastDates} onChange={handleDateChange} value={formData.selectedDate} />
              </Form.Item>
              <Button width='100%' variant="primary" onClick={handleNext} disabled={!formData.selectedDate} style={{ width: '100%', borderRadius: '8px' }}>
                Save Date & Next
              </Button>
            </Form>
          )}

          {currentStep === 1 && (
            <UICol gap='1rem'>
              {formData.travelers.map((traveler, index) => (
                <UICol key={index} gap=''>
                  <Row><Text>Person {index + 1} image</Text></Row>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    {traveler.photo ? (
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <img
                          src={URL.createObjectURL(traveler.photo)} 
                          alt="Traveler Photo"
                          style={{ width: '100%', maxHeight: '200px', borderRadius: '8px' }}
                        />
                        <FontAwesomeIcon icon={faTrash}
                          
                           style={{ marginLeft: '8px' }}
                          onClick={() => handlePhotoDelete(index)}
                          />
                      </div>
                    ) : (
                      <InputFile multiple={false} label={'Upload image'} onFileChange={(files) => handlePhotoChange(files, index)}>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </InputFile>
                    )}
                  </div>
                </UICol>
              ))}
              <UIRow style={{ marginTop: '20px' }} gap='1rem' a='center'>
                <Button width='100%' variant="primary" onClick={handlePrevious} style={{ marginRight: '10px' }}>
                  Previous
                </Button>
                <Button width='100%' 
                  variant="primary"
                  onClick={handleNext} 
                  disabled={formData.travelers.some((traveler) => traveler.photo === null)} 
                  style={{  borderRadius: '8px' }}>
                  Next
                </Button>
              </UIRow>
            </UICol>
          )}

          {currentStep === 2 && (
            <Form layout="vertical">
              {formData.travelers.map((traveler, index) => (
                <UICol key={index} gap='1rem'>
                  <Text strong>Traveler {index + 1}</Text>
                  <Form.Item label="Passport Number">
                    <Input 
                      placeholder="Enter passport number" 
                      value={traveler.passportNumber} 
                      onChange={(e) => handlePassportChange(e, index)} 
                    />
                  </Form.Item>
                  <Form.Item label="Upload Passport">
                    {traveler.passportFile ? (
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <img
                          src={URL.createObjectURL(traveler.passportFile)} 
                          alt="Traveler Photo"
                          style={{ width: '100%', maxHeight: '200px', borderRadius: '8px' }}
                        />
                        <FontAwesomeIcon icon={faTrash} 
                        style={{ marginLeft: '8px' }}
                          onClick={() => handlePassportDelete(index)}
                          />
                      </div>
                    ) : (
                      <InputFile multiple={false} label={'Upload image'} onFileChange={(files) => handlePassportUpload(files, index)}>
                      </InputFile>
                    )}
                      
                  </Form.Item>
                </UICol>
              ))}
             <UIRow gap='1rem' a='center' j='center'>
               <Button width='100%' variant="primary" onClick={handlePrevious} style={{ marginRight: '10px' }}>
                Previous
              </Button>
              <Button width='100%' 
                variant='primary'
                onClick={handleNext} 
                disabled={formData.travelers.some((traveler) => traveler.passportNumber === '' || traveler.passportFile === null)} 
                style={{ borderRadius: '8px' }}>
                Next
              </Button>
             </UIRow>
            </Form>
          )}

          {currentStep === 3 && (
            <Form layout="vertical">
              {formData.travelers.map((traveler, index) => (
                <UICol key={index}>
                  <UIRow p={'1rem 0'}><Text strong>Traveler {index + 1}</Text></UIRow>
                  <Form.Item label="First Name">
                    <Input 
                      placeholder="Enter first name" 
                      value={traveler.firstName} 
                      onChange={(e) => handleInputChange(e, index, 'firstName')} 
                    />
                  </Form.Item>
                  <Form.Item label="Last Name">
                    <Input 
                      placeholder="Enter last name" 
                      value={traveler.lastName} 
                      onChange={(e) => handleInputChange(e, index, 'lastName')} 
                    />
                  </Form.Item>
                  <Form.Item label="Email">
                    <Input 
                      placeholder="Enter email" 
                      value={traveler.email} 
                      onChange={(e) => handleInputChange(e, index, 'email')} 
                    />
                  </Form.Item>
                </UICol>
              ))}
              <UIRow gap='1rem'>
                <Button width='100%' variant='primary' onClick={handlePrevious} style={{ marginRight: '10px' }}>
                Previous
              </Button>
              <Button 
              width='100%'
                variant='primary'
                onClick={handleNext} 
                disabled={formData.travelers.some((traveler) => traveler.firstName === '' || traveler.email === '')} >
                Next
              </Button>
              </UIRow>
            </Form>
          )}

          {currentStep === 4 && (
            <div>
              <Text strong>Proceed with payment.</Text>
              <UIRow j='between' a='center' style={{ gap: '1rem' }} p={'1rem'}>
                <Button variant='primary' onClick={handlePrevious} >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: '#52c41a',
                    borderColor: '#52c41a',
                    width: '100%',
                    borderRadius: '8px',
                  }}
                >
                  Proceed to Checkout
                </Button>
              </UIRow>
            </div>
          )}
        </div>
      </div>
      <PopupStatus mode={popupMode} onClose={closePopup} message={popupMessage} />
    </UIRow>
  );
};

export default FormComponent;