import VisaApp from 'app/components/Atlys/Component/VisaApp';
import { getCollection } from 'firebaseServices/firestore';
import { useEffect } from 'react';

const App = () => {

  return (
    <VisaApp/>
  );
};

export default App;
