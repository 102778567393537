import React, { useEffect, useState } from 'react';
import VisaApplication from './VisaApplication';
import { Col, Img, Row, Text } from 'ui/basic';
import Icon, { IconName } from 'ui/Icon';
import Logo from './../../../../assets/Logo/ecc.png';
import { useTheme } from 'styled-components';
import { CountryVisa } from 'app/interfaces/types';
import { LoadingAnimation } from 'ui/LoadingAnimation';
import { getVisas } from 'app/services/getVisas';

const visaTypes = ['All', 'tourist', 'student', 'work'];

const calculateGetOnDate = (visaDays: number) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + visaDays);
  return currentDate.toLocaleString('en-IN', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const CountryVisaCard: React.FC<CountryVisa & { onClick: () => void }> = ({ countryName, visaDays, pricePerPerson, image, onClick }) => {
  const getOnDate = calculateGetOnDate(visaDays);
const theme = useTheme();
  return (
    <div
      onClick={onClick}
      style={{
        border: '1px solid #ddd',
        borderRadius: '12px',
        overflow: 'hidden',
        width: '100%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        marginBottom: '16px',
        cursor: 'pointer',
      }}
    >
      <div style={{ position: 'relative' }}>
        <Row h='300px'>
          <img
            src={image}
            alt={countryName}
            style={{ height: '100%',width:'100%', objectFit: 'cover' }}
          />
        </Row>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            backgroundColor: theme.brandColor.primary,
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '6px',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {`${visaDays} Days Visa`}
        </div>
      </div>

      <div style={{ padding: '12px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ fontSize: '18px', fontWeight: 'bold', margin: '0', color: '#333' }}>
            {countryName}
          </h2>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#000', margin: '0' }}>
            ₹{pricePerPerson.toLocaleString()}
          </p>
        </div>

        <p style={{ fontSize: '14px', color: '#555', margin: '8px 0 0' }}>
          Get on{' '}
          <span style={{ color: theme.brandColor.primary, fontWeight: 'bold' }}>{getOnDate}</span>
        </p>
      </div>
    </div>
  );
};

const HistoryComponent: React.FC<{ visaHistory: CountryVisa[] }> = ({ visaHistory }) => {
  return (
    <Col>
      <Text s='24' w={6} >Visa Application History</Text>
      {visaHistory.map((visa, index) => (
        <Row key={index} style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '1rem', marginBottom: '1rem' }}>
          <Col>
            <Text s='18' w={6}>{visa.countryName}</Text>
            <Text s='14'>{visa.visaType} visa - {visa.visaDays} days</Text>
            <Text s='14'>Applied on: {new Date().toLocaleDateString()}</Text>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

const VisaApp: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('All');
  const [selectedVisa, setSelectedVisa] = useState<CountryVisa | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [visas, setVisas] = useState<CountryVisa[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState('home');
  const [visaHistory, setVisaHistory] = useState<CountryVisa[]>([]);
  const theme = useTheme();

  const filteredData = visas.filter((visa) => {
    const matchesSearch =
      visa.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      visa.countryName.toUpperCase().includes(searchQuery.toUpperCase());
    const matchesTab = activeTab === 'All' || visa.visaType === activeTab;
    return matchesSearch && matchesTab;
  });

  useEffect(() => {
    const fetchVisas = async () => {
      try {
        const visasArray = await getVisas();
        console.log('visa ',visasArray)
        setVisas(visasArray);
      } catch (error) {
        console.error('Error fetching visas:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVisas();
  }, []);

  const handleVisaApplication = (visa: CountryVisa) => {
    setSelectedVisa(visa);
    setVisaHistory([...visaHistory, visa]);
  };

  const renderContent = () => {
    if (selectedVisa) {
      return (
        <Row j='center'>
          <Row a='center' j='center' style={{ maxWidth: '800px' }}>
            <VisaApplication
              visa={selectedVisa}
              onBack={() => setSelectedVisa(null)}
            />
          </Row>
        </Row>
      );
    }

    if (activePage === 'history') {
      return <HistoryComponent visaHistory={visaHistory} />;
    }

    return (
      <>
        <Row p={'1rem'} style={{ width: '100%' }}>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter destination"
            style={{ width: '100%', padding: '8px', fontSize: '16px', borderRadius: '8px', border: '1px solid #ddd' }}
          />
        </Row>
        <Row p={'0 1rem 1rem'} j='between' style={{ display: 'flex', width: '100%' }}>
          {visaTypes.map((type) => (
            <button
              key={type}
              onClick={() => setActiveTab(type)}
              style={{
                padding: '8px 8px',
                fontSize: '16px',
                borderRadius: '8px',
                backgroundColor: activeTab === type ? theme.brandColor.primary : '#f0f0f0',
                color: activeTab === type ? '#fff' : '#000',
                border: 'none',
                cursor: 'pointer',
                width: '23%'
              }}
            >
              <Text tt='cap'> {type}</Text>
            </button>
          ))}
        </Row>
        <Row p={'1rem'} style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', width: '100%' }}>
          {filteredData.length > 0 ? (
            filteredData.map((visa, index) => (
              <CountryVisaCard
                key={index}
                {...visa}
                onClick={() => handleVisaApplication(visa)}
              />
            ))
          ) : (
            <p>No visas available for the selected filter.</p>
          )}
        </Row>
      </>
    );
  };

  return (
    <Row j='center'>
      <Row a='center' j='center' style={{ maxWidth: '800px', width: '100%' }}>
        <Col style={{ alignItems: 'center', fontFamily: 'Arial, sans-serif', width: '100%' }}>
          <Col p={'1rem'} style={{ position: 'sticky', top: 0, zIndex: 1000, background: 'white', boxShadow: theme.shadow.shadow1 }}>
            <Row gap='0.5rem' a='center'>
              <Icon height={1.5} width={1.5} name={IconName.Bars} onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ cursor: 'pointer' }} />
              <Img h='2rem' w='2rem' src={Logo} />
              <Text s='24' w={7} c={theme.brandColor.primaryActive}> Europe Career consultant</Text>
            </Row>
            <Row p={'0.25rem 5rem 0.5rem'}>
              <Text tt='cap' s='16' w={6} c={theme.neutralColor.text}>your vision, our process</Text>
            </Row>
          </Col>
          <Row style={{ position: 'relative', width: '100%' }}>
            {isSidebarOpen && (
              <Col style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '200px',
                height: '100%',
                backgroundColor: '#f0f0f0',
                padding: '1rem',
                boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                zIndex: 1000
              }}>
                <Text
                  s='18'
                  w={6}
                  style={{
                    cursor: 'pointer',
                    marginBottom: '1rem',
                    padding: '0.5rem',
                    backgroundColor: activePage === 'home' ? theme.brandColor.primary : 'transparent',
                    color: activePage === 'home' ? '#fff' : '#000',
                    borderRadius: '4px'
                  }}
                  onClick={() => { setActivePage('home'); setIsSidebarOpen(false); }}
                >
                  Home
                </Text>
                <Text
                  s='18'
                  w={6}
                  style={{
                    cursor: 'pointer',
                    padding: '0.5rem',
                    backgroundColor: activePage === 'history' ? theme.brandColor.primary : 'transparent',
                    color: activePage === 'history' ? '#fff' : '#000',
                    borderRadius: '4px'
                  }}
                  onClick={() => { setActivePage('history'); setIsSidebarOpen(false); }}
                >
                  History
                </Text>
              </Col>
            )}
            <Col style={{ width: '100%', marginLeft: isSidebarOpen ? '200px' : '0', transition: 'margin-left 0.3s' }}>
              {isLoading ? <LoadingAnimation /> : renderContent()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default VisaApp;