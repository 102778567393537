const brandColor = {
  primaryBg: "#f0fcff",
  primaryBgHover: "#d9f7ff",
  primaryBorder: "#b0edff",
  primaryBorderHover: "#82d6f5",
  primaryHover: "#56bce8",
  primary: "#2ea3dc",
  primaryActive: "#1d7db5",
  primaryTextHover: "#56bce8",
  primaryText: "#2ea3dc",
  primaryTextActive: "#1d7db5",
  pinkBg: "#fdeaf1",
  pinkBgHover: "#f7c7dc",
  pinkBorder: "#f199b1",
  pinkBorderHover: "#e87392",
  pinkHover: "#e51579",
  pink: "#e51579",
  pinkActive: "#c20f62",
  pinkTextHover: "#e51579",
  pinkText: "#e51579",
  pinkTextActive: "#c20f62",

  red: "#d90429",
};

const errorColor = {
  errorBg: "#fff2f0",
  errorBgHover: "#ffefed",
  errorBorder: "#ffc9c4",
  errorBorderHover: "#ffa19c",
  errorHover: "#ff7573",
  error: "#f5474a",
  errorActive: "#cf3239",
  errorTextHover: "#ff7573",
  errorText: "#f5474a",
  errorTextActive: "#cf3239",
};
const neutralColor = {
  text: "rgba(0, 0, 0, 0.88)",
  textSecondary: "rgba(0, 0, 0, 0.65)",
  textTertiary: "rgba(0, 0, 0, 0.45)",
  textQuaternary: "rgba(0, 0, 0, 0.25)",
  border: "#d9d9d9",
  borderSecondary: "#f0f0f0",
  fill: "rgba(0, 0, 0, 0.15)",
  fillSecondary: "rgba(0, 0, 0, 0.06)",
  fillTertiary: "rgba(0, 0, 0, 0.04)",
  fillQuaternary: "rgba(0, 0, 0, 0.02)",
  bgContainer: "#ffffff",
  bgElevated: "#ffffff",
  bgLayout: "#f0f1f3b0",
  bgSpotlight: "rgba(0, 0, 0, 0.85)",
  bgMask: "rgba(0, 0, 0, 0.45)",
};

const font = {
  fontSize: "14px",
  fontSizeSM: "12px",
  fontSizeLG: "16px",
  fontSizeXL: "20px",
  fontSizeHeading1: "38px",
  fontSizeHeading2: "30px",
  fontSizeHeading3: "24px",
  fontSizeHeading4: "20px",
  fontSizeHeading5: "16px",
};
const lineHeight = {
  lineHeight: 1.5714285714285714,
  lineHeightSM: 1.6666666666666667,
  lineHeightLG: 1.5,
  lineHeightHeading1: 1.2105263157894737,
  lineHeightHeading2: 1.2666666666666666,
  lineHeightHeading3: 1.3333333333333333,
  lineHeightHeading4: 1.4,
  lineHeightHeading5: 1.5,
};
const shadow = {
  shadow1: "rgba(0, 0, 0, 0.082) 0px 1px 4px",
  boxShadowSecondary: "rgba(60, 66, 87, 0.08) 0px 15px 35px 0px, rgba(0, 0, 0, 0.12) 0px 5px 15px 0px",
  boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)",
  boxShadowTertiary: "0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px -2px rgba(0, 0, 0, 0.08), 0 6px 16px 4px rgba(0, 0, 0, 0.03)",
};

const theme = {
  brandColor,
  neutralColor,
  font,
  lineHeight,
  shadow,
  errorColor,
};

export default theme;
