const firebaseConfig = {
  apiKey: "AIzaSyBe6WMiv6clRyF75pc6oC8RXXiSQeq5-SQ",
  authDomain: "visaapp-e90c7.firebaseapp.com",
  projectId: "visaapp-e90c7",
  storageBucket: "visaapp-e90c7.appspot.com",
  messagingSenderId: "865837156243",
  appId: "1:865837156243:web:11f1e3a67e043958248a66",
  measurementId: "G-KRC4732R5M"
};

export default firebaseConfig
