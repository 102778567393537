import { CountryVisa } from "app/interfaces/types";
import { uploadImageToFirebase } from "firebaseServices/Storage";
import { createAutoIdDocument } from "firebaseServices/firestore";


export interface Traveler {
  photo: File | null;
  passportNumber: string;
  passportFile: File | null;
  firstName: string;
  middleName: string;
  lastName?: string;
  email: string;
}

export interface VisaApplication {
  selectedDate: Date | null;
  travelers: Traveler[];
  visa: CountryVisa;
}

export interface ApplyVisaResponse {
  success: boolean;
  message: string;
  error?: any;
}

const applyVisa = async (visaApplication: VisaApplication): Promise<ApplyVisaResponse> => {
  const STORAGE_FOLDER = 'applicantPhotos'
  try {
    const updatedTravelers = await Promise.all(visaApplication.travelers.map(async (traveler) => {
      const photoFileName = traveler.firstName + "_" + traveler.lastName + "_" + "photo_" + Date.now() + ".jpg";
      const passportFileName = traveler.firstName + "_" + traveler.lastName + "_" + "passport_" + Date.now() + ".jpg";

      if (traveler.photo && typeof traveler.photo !== 'string') {
          await uploadImageToFirebase(traveler.photo, photoFileName, STORAGE_FOLDER);
        }

        if (traveler.passportFile && typeof traveler.passportFile !== 'string') {
          await uploadImageToFirebase(traveler.passportFile, passportFileName, STORAGE_FOLDER);
        }

        return {
          ...traveler,
          photo: photoFileName,
          passportFile: passportFileName,
        };
    }));

    const visaApplicationData = {
      selectedDate: visaApplication.selectedDate ? visaApplication.selectedDate.toISOString() : null, // Convert to ISO string
      travelers: updatedTravelers,
      visa: visaApplication.visa,
      userId: 'a'
    };
    console.log('applting ', visaApplicationData)
    await createAutoIdDocument('visaApplications', visaApplicationData);
    return {
      success: true,
      message: 'Visa application successfully submitted.',
    };
  } catch (error) {
    console.error('Error applying for visa:', error);
    return {
      success: false,
      message: 'Error applying for visa.',
      error: error,
    };
  }
};

export default applyVisa;