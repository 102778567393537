import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from 'firebaseServices/firebase';

async function createCollection(location: string, collectionId: string): Promise<void> {
  try {
    const collectionRef = collection(db, location, collectionId);
    await addDoc(collectionRef, {});
  } catch (error) {
    console.log(error,'seller creattion')
    throw new Error('Failed to create collection');
  }
}

export default createCollection;


async function getCollection(location: string): Promise<any[]> {
  try {
    const collectionRef = collection(db, location);
    const querySnapshot = await getDocs(collectionRef);
    const collectionData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return collectionData;
  } catch (error) {
    console.log(error, 'getCollection');
    throw new Error('Failed to get collection');
  }
}

export {getCollection}