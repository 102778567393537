import { CountryVisa } from "app/interfaces/types";
import { getFirebaseImageUri } from "firebaseServices/Storage";
import { getCollection } from "firebaseServices/firestore";

const getVisas = async (): Promise<CountryVisa[]> => {
  const VISA_IMAGE_FOLDER = 'visas';
  try {
    const visas = await getCollection('visas');
    return resolveImages(VISA_IMAGE_FOLDER,visas);
  } catch (error) {
    console.error('Error fetching visas:', error);
    return [];
  }
};

const resolveImages = (location: string,visas: CountryVisa[]): CountryVisa[] => {
  const resolvedVisas = visas.map(visa => ({
    ...visa,
    image: getFirebaseImageUri(`${location}/${visa.image}`)
  }));
  return resolvedVisas;
};

export {getVisas}