import React, { useState } from 'react';
import Button from 'ui/Button';
import Icon, { IconName } from 'ui/Icon';
import { Col, Row ,Text} from 'ui/basic';
import FormComponent from './Form';
import { Drawer } from 'ui/Drawer';
import { CountryVisa } from '../interface/types';
import { useTheme } from 'styled-components';


interface VisaApplicationProps {
  visa: CountryVisa;
  onBack: () => void;
}

const calculateGetOnDate = (visaDays: number) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + visaDays);
  return currentDate.toLocaleString('en-IN', {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};
const VisaApplication: React.FC<VisaApplicationProps> = ({ visa ,onBack}) => {
  const [travellers, setTravellers] = useState(1);

  const {fee = 25000, visaDays, visaType} = visa;
  const guaranteeDate = calculateGetOnDate(visaDays);

  const onlyOneApplicant = visaType === 'work'

  const underlineStyle: React.CSSProperties = {
    textDecoration: 'underline',
  };

  const strikethroughStyle: React.CSSProperties = {
    textDecoration: 'line-through',
    marginRight: '5px',
  };

  const highlightStyle: React.CSSProperties = {
    color: '#4361ee',
    fontWeight: 'bold',
  };

  const totalAmount = visa.pricePerPerson * travellers;
  const [showForm,setShowForm] = useState(false);

  const theme = useTheme();
  const displayApplicationForm = () => setShowForm(true);

  return (
    <Col h='100vh' style={{background:'#f8f8f8'}}>
      <Drawer h='100%' isOpen={showForm}>
        <FormComponent onBack={() => setShowForm(false)} numberOfTravelers={travellers} visa={visa}/>
      </Drawer>
      <Row p={'1rem'} style={{background:'white', boxShadow : theme.shadow.shadow1, position:'sticky',top:0}}>
        <Icon onClick={onBack} name={IconName.GoBack}/>
      </Row>
      <Col p={'1rem'}>
        <Col gap='1rem' bg='#fff' sh={theme.shadow.shadow1}>
          <Col gap='1rem' p={'2rem 1rem'} bg={theme.brandColor.primary} br='1rem 1rem 0 0 ' style={{boxShadow:theme.shadow.shadow1}}>
              <Text as='h1' s='22' c='white' w={6}>{visa.countryName} Visa for Indian Citizens</Text>
              <Row j='end'>
                <Text tt='cap' as='h4' c={theme.brandColor.primaryBorderHover} w={5}>{visaType} Visa</Text>
              </Row>
          </Col>
          <Row p={'1rem'} gap='1rem'>
            <span style={{color:theme.brandColor.primary,fontWeight:'bold'}}>✓</span>
            Europe Career Consultant is officially authorized by Government of {visa.countryName}
          </Row>
          <Row p={'1rem'} gap='1rem'>
            <span style={{color:theme.brandColor.primary,fontWeight:'bold'}}>✓</span>
            <div>
              <div>Visa guaranteed on</div>
              <div style={{fontWeight: 'bold'}}>{guaranteeDate}</div>
            </div>
          </Row>
          {!onlyOneApplicant && 
            <Row p={'1rem'} j='between'>
              <Row a='end' w='initial' gap='1rem'>
                <span style={{marginRight: '5px'}}>👥</span>
                Travellers
              </Row>
              <Row w='initial' a='center'>
                <Button   onClick={() => setTravellers(Math.max(1, travellers - 1))}>-</Button>
                <span style={{margin: '0 10px'}}>{travellers}</span>
                <Button  onClick={() => setTravellers(travellers + 1)}>+</Button>
              </Row>
            </Row>
          }
          <Col p={'1rem'} gap='0.5rem'>
            <Text s='16' w={6} mb='1rem'>Price</Text>
            <Row j='between' p={' 0 0 0.5rem'}>
              <Row gap='0.5rem' w='initial'>
                <span style={{marginRight: '5px'}}>💳</span>
                <span style={underlineStyle}>Visa Fees</span>
              </Row>
              <div>₹{visa.pricePerPerson.toLocaleString()} × {travellers}</div>
            </Row>
            <Row j='between' p={' 0 0 0.5rem'}>
              <Row gap='0.5rem' w='initial'>
                <span style={{color:theme.brandColor.primary,fontWeight:'bold',marginRight: '5px'}}>✓</span>
                <span style={underlineStyle}>Consultant Fees</span>
              </Row>
              <Col w='initial' a='end'>
                <span style={strikethroughStyle}>₹{fee}</span>
                <span style={highlightStyle}>₹0 for now</span>
              </Col>
            </Row>
            <div style={{fontSize: '14px', color: '#666', marginTop: '5px'}}>
              You pay ₹{fee} only when we deliver your visa on time
            </div>
          </Col>
          <Row p={'1rem'} j='between'>
            <Text s='16' w={6} mb='1rem'>Total Amount</Text>
            <Text s='16' w={6} mb='1rem'>₹{totalAmount.toLocaleString()}</Text>
          </Row>
          <Row p={'1rem'}>
            <Button padding='1rem' onClick={displayApplicationForm} width='100%' size='large' >
              <Text s='16' w={6} >Start Application</Text>
              </Button>
          </Row>
          <Row p={'1rem'}>
            {visa.visaType.charAt(0).toUpperCase() + visa.visaType.slice(1)} visa for {visa.visaDays} days
          </Row>
        </Col>
      </Col>
    </Col>
  );
};

export default VisaApplication;